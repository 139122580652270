import React, { useEffect, useState } from 'react'

import { getInvoicePdf, getInvoices } from 'api/invoices'
import BootstrapTable from 'apps/shared/components/BootstrapTable'
import { EmptyListIcon, InvoiceStatusBadge } from 'apps/shared/components/Icons'
import {
    CustomerInvoiceType,
    CustomerType,
} from 'apps/vendor/interfaces/subscriptions'
import { formatDate } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import { primary } from 'utils/colors'

import { TableWrapper } from '../Tables.styled'

export default function InvoicesTable(props: { customer: CustomerType }) {
    const { customer } = props
    const { errorToast } = useToast()
    const { user } = useUser()

    const [invoices, setInvoices] = useState<CustomerInvoiceType[] | null>(
        customer.invoices || null,
    )

    useEffect(() => {
        getInvoices(customer.id)
            .then((res: any) => {
                if (res.data !== customer.invoices) {
                    customer.invoices = res.data
                }
                setInvoices(res.data.reverse())
            })
            .catch(() => {
                errorToast('Failed to fetch invoices')
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getHeaders = () => [
        'Invoice number',
        'Total',
        'Date sent',
        'Due date',
        'Status',
        '',
    ]

    const handleOpenPdf = (objectId: number) => {
        getInvoicePdf(objectId)
            .then((res) => {
                const invoiceUrl = res.data

                window.location.href = invoiceUrl
            })
            .catch(() => {
                errorToast('Failed to download PDF')
            })
    }

    const getData = () => {
        if (!invoices) return []

        return invoices.map((invoice: CustomerInvoiceType) => {
            let statusText = invoice.status.toUpperCase()

            if (statusText === 'TRANSFER CREATED') {
                statusText = 'PAID'
            }

            return {
                created_at: formatDate(
                    new Date(invoice.created_at),
                    user?.locale,
                ),
                due_date: formatDate(new Date(invoice.due_date), user?.locale),
                status: <InvoiceStatusBadge status={statusText} />,
                invoice_number: invoice.stripe_invoice_number,
                total: invoice.invoice_total,
                pdf: (
                    <button
                        type="button"
                        onClick={() => handleOpenPdf(invoice.id)}
                        className="fa-solid fa-file-pdf fa-lg pdf-icon"
                        style={{
                            color: primary.darkRed,
                            background: 'none',
                            border: 'none',
                        }}
                        aria-label="Open PDF"
                    />
                ),
            }
        })
    }

    const getAccessors = () => [
        'invoice_number',
        'total',
        'created_at',
        'due_date',
        'status',
        'pdf',
    ]

    return invoices?.length === 0 ? (
        <EmptyListIcon />
    ) : (
        <TableWrapper numberOfColumns={6}>
            <BootstrapTable
                headers={getHeaders()}
                data={getData()}
                accessors={getAccessors()}
                centeredHeaders={['Status', '']}
                rightAlignedHeaders={['Total']}
            />
        </TableWrapper>
    )
}
